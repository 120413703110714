import * as React from 'react';
import { Link } from 'gatsby';
import { GlobalStyle } from '../style';
import GlobalLayout from '../layouts/GlobalLayout';

// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};

// markup
const NotFoundPage = () => {
  return (
    <GlobalLayout>
      <GlobalStyle style={{ pageStyles }}>
        <title>Not found</title>
        <h1 style={headingStyles}>Ain't no page named that</h1>
        <p style={paragraphStyles}>
          Sorry{' '}
          <span role='img' aria-label='Pensive emoji'>
            😔
          </span>{' '}
          <Link to='/'>Go home</Link>.
        </p>
      </GlobalStyle>
    </GlobalLayout>
  );
};

export default NotFoundPage;
